/* eslint-disable react/prop-types */
import React from 'react'
import { Link } from 'gatsby'
import { FaHome } from 'react-icons/fa'
import { motion } from 'framer-motion'

function PrivacyHeader (props) {
  return (
    <div className="flex justify-center my-8">
      <h2 className="w-10/12 md:w-8/12 lg:w-6/12 xl:w-4/12 font-headers self-center text-gray-800 text-xl lg:text-3xl">{props.header}</h2>
    </div>
  )
}

function PrivacyPara (props) {
  return (
    <div className="flex justify-center my-4">
      <p className="w-10/12 md:w-8/12 lg:w-6/12 xl:w-4/12 self-center font-content text-gray-800 text-lg lg:text-md">{props.paraContent}</p>
    </div>
  )
}

function PrivacyParaWLink (props) {
  return (
    <div className="flex justify-center my-4">
      <p className="w-10/12 md:w-8/12 lg:w-6/12 xl:w-4/12 self-center font-content text-gray-800 text-lg lg:text-md"><span className="text-gray-800 font-content text-lg lg:text-md font-bold">{props.paraBold}</span> {props.paraBefore} <a className="text-purple-600" href={props.linkRef} target="_blank" rel="noreferrer">{props.linkMessage}</a> {props.paraAfter}</p>
    </div>
  )
}

function PrivacyPolicy () {
  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.3 }} className="py-12">
      <div className="flex justify-center mb-8">
        <h2 className="w-10/12 md:w-8/12 lg:w-6/12 xl:w-4/12 text-center font-headers self-center text-gray-800 text-xl lg:text-3xl">Privacy Policy for Noise Filter</h2>
      </div>
      <div className="flex justify-center my-4">
        <p className="w-10/12 md:w-8/12 lg:w-6/12 xl:w-4/12 self-center font-content text-gray-800 text-lg lg:text-md italic">Last updated 02 April 2020</p>
      </div>
      <PrivacyPara paraContent={'Thank you for taking the time to review the privacy policy for Noise Filter.'}/>
      <PrivacyPara paraContent={'One of the primary aims when designing this extension was to ensure that any data collected by the application was critical for the application to function. Furthermore, it has been developed in such a way that this information is stored on the users device and as such is not accessible to the developer or any third-party.'}/>
      <PrivacyPara paraContent={'In this document, I will explain what data is collected by the application, why this data is necessary in the context of the application, and how this data is protected.'}/>
      <PrivacyHeader header={'Access to Tab Content'}/>
      <PrivacyPara paraContent={'Noise Filter works on a specific subset of websites (please see the coverage page for an up-to-date list of websites). Restricting the number of websites that the application works with ensures that the application doesn\'t affect the presentation of a website without first being tested. As such, Noise Filter maintains a record of the website being viewed to check whether the extension functionailty is required on the current website.'}/>
      <PrivacyPara paraContent={'The URL of the currently open tab is retained within the extension for the length of time that the user accesses the particular website and is not stored or accessible by any third-party.'}/>
      <PrivacyPara paraContent={'When installing the extension you will be asked to provide your informed consent to allow the extension access to this information.'}/>
      <PrivacyHeader header={'Whitelisting'}/>
      <PrivacyPara paraContent={'One of the primary features of Noise Filter is the ability to whitelist specific websites. To enable this feature, Noise Filter maintains a record of the websites whitelisted by the user and compares the list with the current website to determine whether or not to block the comment section.'}/>
      <PrivacyPara paraContent={'This information is stored locally on your device which ensures that the whitelist is accessible each time you open the web browser. This data is only retrieved by the application for the aforementioned purpose; it is not accessible to the developer or by any third-party. However, please note that the storage is not encrypted.'}/>
      <PrivacyPara paraContent={'As above, when installing the extension you will be asked to provide your informed consent to allow the extension to store and retrieve this information.'}/>
      <PrivacyHeader header={'Browser Analytics'}/>
      <PrivacyPara paraContent={'Some third-party data may be generated by the web browser when using Noise Filter. For details of what data may be collected, please take the time to read the privacy policy for your selected we browser:'}/>
      <PrivacyParaWLink paraBold={'Firefox:'} linkRef={'https://www.mozilla.org/en-US/privacy/firefox/#addons'} linkMessage={'Add-ons Privacy Policy'} paraBefore={'Please visit Mozilla\'s'} paraAfter={'for information relating to the data they collect from Firefox add-ons.'}/>
      <PrivacyParaWLink paraBold={'Chrome:'} linkRef={'https://www.google.com/chrome/privacy/#privacy-practices-of-apps'} linkMessage={'Privacy Practices'} paraBefore={'Please visit Google\'s'} paraAfter={'for information relating to the data they collect from Google extensions.'}/>
      <PrivacyParaWLink paraBold={'Edge:'} linkRef={'https://support.microsoft.com/en-gb/help/4468242/microsoft-edge-browsing-data-and-privacy'} linkMessage={'Browsing Data and Privacy Policy'} paraBefore={'Please visit Microsoft\'s'} paraAfter={'for information relating to the data they collect from Edge extensions.'}/>
      <PrivacyPara paraContent={'Any data provided by the web browser to Noise Filter will be anonymised. The only data that I will use will relate to tracking the number of active users. I will not share or sell this information to a third-party.'}/>
      <PrivacyPara paraContent={'Each browser will allow you to opt-out of certain data collection and will also provide means for removing previously collected data. Please refer to the aforementioned documents for your specific browser to find instructions on how to accomplish this.'}/>
      <div className="flex justify-center my-12">
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.3 }} className="flex">
          <button className="min-w-64 h-12 self-center bg-purple-700 border border-solid border-gray-200 rounded shadow transition duration-500 ease-in-out transform hover:scale-105">
            <Link to={'/'}>
              <div className="flex justify-center center-content">
                <FaHome color="white" className="self-center mr-3"/>
                <span className="font-content text-gray-200 text-lg">Return to Home</span>
              </div>
            </Link>
          </button>
        </motion.div>
      </div>
    </motion.div>
  )
}

export default PrivacyPolicy
